import React from 'react';
import './About.scss';
import { motion } from 'framer-motion';
import { bios } from '../../../Data';
import portfolio from '../../../assets/portfolio.gif';

const About = () => {
  return (
    <div className="container" id="about">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ y: [-20, 0], opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="title"
      >
        <span>Who Am I?</span>
        <h1>About Me</h1>
      </motion.div>

      <div className="about_container">
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="about_left"
        >
          <motion.img
            src={portfolio}
            whileHover={{ y: -5, scale: 1.05 }}
            transition={{ duration: 0.3 }}
            alt="About me"
            className="about_gif"
          />
        </motion.div>
        <motion.div
          className="about_right"
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <p>
            I began studying technology at the age of 16. Originally from Maceió, I gained experience working as a logistics assistant and an administrative assistant. Over time, I transitioned into the IT field. As I delved deeper into the world of Information Technology, I accumulated more and more experience, and found a true passion for the field.
            <br />
            My journey in the tech industry has allowed me to develop a unique skill set and a deep understanding of various aspects of IT. I am dedicated to finding innovative solutions and continuously improving my expertise.
          </p>
          {bios.map(bio => (
            <div className="bio" key={bio.id}>
              <span className="bioKey">{bio.icon} {bio.key}</span>
              <span className="bioValue">{bio.value}</span>
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default About;
